import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const SearchContainer = styled.div`
  ${tw`px-0 xl:px-0 lg:flex flex-row flex-wrap justify-center mb-32 h-full`}

  > div {
    max-width: 830px;
  }

  .location-info-title-wrap {
    width: 80%;
    ${tw`justify-center`}
  }

  @media (max-width: 767px) {
    .location-info {
      ${tw`text-left`}
    }

    .location-info-title-wrap {
      ${tw`text-left justify-start`}
    }
  }
`
export const Container = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`max-w-full max-h-full h-full w-full`}

  ${({ template }) =>
    template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      .location-search-form {
        label {
          ${tw`text-white text-lg normal-case`}
        }
      }
    `}
`
