import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'
import * as Scroll from 'react-scroll'
const Element = Scroll.Element

export const LocationCardStyled = styled(Element)<{
  template?: LAYOUT_TEMPLATE
}>`
  min-height: 150px;
  ${tw`relative bg-grey__light mb-2.5 p-4 h-full font-display w-full`}

  &:after {
    ${tw`absolute bg-orange text-white h-full w-4 right-0 top-0 flex flex-col justify-center text-center text-xl`}
    content: '\\276D'
  }

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-black__deep border border-white hover:bg-blue__deep hover:text-white hover:border-blue__deep`}

      @media (max-width: 767px) {
        width: calc(100% - 1.875rem);
      }

      &:after {
        background-image: url('/static/svgs/icon-angle-right.svg');
        background-size: 5px;
        ${tw`bg-black__deep w-8 bg-center bg-no-repeat`}
        content: ' '
      }

      &:hover:after {
        ${tw`bg-blue__deep`}
      }

      ${LocationCardTitle} {
        ${tw`text-white uppercase font-bold font-youfitnessHeading`}
      }

      ${LocationFeatures} {
        ${tw`text-white`}
      }

      ${LocationInfo} {
        & address {
          ${tw`text-white`}
        }

        & p {
          ${tw`text-white font-normal`}
        }

        & a {
          ${tw`text-white font-bold underline`}
        }
      }

      ${LocationCardOpenSoon} {
        ${tw`text-white bg-blue__deep`}
      }
    `}
`

export const LocationLink = styled.a`
  ${tw`absolute w-full h-full z-10 top-0 left-0`}
`

export const LocationTitleWrapper = styled.div`
  ${tw`flex items-center flex-wrap`}
  margin-bottom: 1.25rem;
`

export const LocationCardTitle = styled.h3`
  ${tw`text-blue__dark font-extrabold text-xl mr-1 mb-0`}
`

export const LocationCardOpenSoon = styled.div`
  ${tw`bg-orange text-white font-extrabold text-xs`}
  border-radius: 2rem;
  padding: 0px 7px;
  white-space: nowrap;
  vertical-align: middle;
  z-index: 10;
  position: relative;
`

export const LocationInfo = styled.div`
  width: 100%;
  ${tw`flex flex-1 justify-between flex-col h-full`}

  & address {
    width: 80%;
    ${tw`not-italic`}
    & p {
      ${tw`text-blue text-sm font-extrabold mb-0`}
    }

    & a {
      ${tw`text-blue__dark text-sm font-extrabold relative z-40`}
    }
  }

  a {
    width: 80%;
  }
`

export const LocationFeatures = styled.div`
  right: 1rem;
  bottom: 1rem;
  ${tw`text-orange absolute z-10`}
`

export const FeatureIcon = styled.a<{ iconImg?: string }>`
  ${props =>
    props.iconImg && `background: url(${props.iconImg}) center/150% no-repeat;`}
  ${tw`w-8 h-8`}
  pointer: cursor;
`

export const FeatureBadge = styled.span`
  ${tw`absolute bg-blue text-white text-center text-xs rounded-lg px-2 hidden md:inline`}
  bottom: 2.75rem;
  right: 2.75rem;

  @media (min-width: 1024px) {
    right: 2rem;
  }

  &:after {
    ${tw`absolute text-blue`}
    content: '\\25BC';
    top: 0.75rem;
    right: 10%;
  }
`

export const ComingSoonSection = styled.div`
  ${tw`w-full flex flex-col items-center pt-6 pb-4 self-center mx-auto`}
  max-width: 1280px;
  & > div {
    ${tw`w-full flex flex-row flex-wrap justify-center`}
    & > div {
      ${tw`w-full lg:w-1/4 text-grey text-center flex flex-col justify-center items-center m-2 px-12 bg-grey__light`}
      min-height: 120px;

      & > h6 {
        ${tw`m-0`}
      }
    }
  }
`

export const GymLocationResultWrapper = styled.div`
  ${tw`w-11/12 lg:w-full lg:flex flex-row flex-wrap justify-center mb-32`}
`

export const GymLocationEmptyText = styled.div`
  ${tw`w-full text-center mt-6 mb-8`}
`

export const Wrapper = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`w-full pb-20`}

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      h2 {
        ${tw`text-white`}
      }
    `}
`
