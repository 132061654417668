import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import TextInput from './TextInput'
import moment from 'moment'
import { AvailabilityDateTime } from '../../services/api/types'
import styled from 'styled-components'

const DatePickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__time-list-item--disabled {
    display: none;
  }
`;

export interface DatePickerProps {
  name: string
  setFieldValue: Function
  showYearDropdown?: boolean
  placeholder?: string
  timePicker?: boolean
  availabilityDateTime?: Array<AvailabilityDateTime>
}

export const DatePickerStyled = (props: DatePickerProps) => {
  const { setFieldValue, name, showYearDropdown, placeholder, timePicker, availabilityDateTime } = props
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [minTime, setMinTime] = useState<Date>(new Date('1970-01-01T00:00:00'));
  const [maxTime, setMaxTime] = useState<Date>(new Date('1970-01-01T23:59:00'));
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (selectedDate) {
      setMinTime(getTimeByDayOfWeek(selectedDate.getDay(), 'start_time'));
      setMaxTime(getTimeByDayOfWeek(selectedDate.getDay(), 'end_time'));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (initialLoad) {
      setMinTime(getTimeByDayOfWeek(new Date().getDay(), 'start_time'));
      setMaxTime(getTimeByDayOfWeek(new Date().getDay(), 'end_time'));
      setInitialLoad(false);
    }
  }, []);

  const CustomInputDate = ({ value, onClick }: any) => (
    <TextInput
      onClick={onClick}
      value={value}
      borderColor='white'
      color='black'
      className='w-full'
      placeholder={placeholder || 'Select date'}
    />
  )

  const filterTime = (time: Date | null) => {
    if (!time) {
      return false
    }
    const dayOfWeek = time.getDay() // 0: Sunday, 1: Monday, ...
    const availableDay = availabilityDateTime?.find(
      availability =>
          parseInt(availability.day_of_week) === (dayOfWeek === 0 ? 7 : dayOfWeek + 1)
    )

    if (availableDay) {
      const startTime = moment(availableDay.start_time, 'HH:mm:ss')
      const endTime = moment(availableDay.end_time, 'HH:mm:ss')
      const currentTime = moment(time)

      const startTimeMinutes = startTime.hours() * 60 + startTime.minutes()
      const endTimeMinutes = endTime.hours() * 60 + endTime.minutes()
      const currentTimeMinutes =
        currentTime.hours() * 60 + currentTime.minutes()

      return (
        currentTimeMinutes >= startTimeMinutes &&
        currentTimeMinutes <= endTimeMinutes
      )
    }

    return false
  }

  const onDateChange = (date: any) => {
    setSelectedDate(date)
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD h:mm A')
    setFieldValue(name, formattedDate)
  }

  const isDateAvailable = (date: Date) => {
    const day = date.getDay();
    if (!availabilityDateTime || availabilityDateTime.length === 0) {
      return true;
    }
    const dayOfWeekArray = availabilityDateTime.map((item) => parseInt(item.day_of_week));
    return dayOfWeekArray.includes(day);
  };

  const getTimeByDayOfWeek = (dayOfWeek: number, timeProperty: string) => {
    if (availabilityDateTime && availabilityDateTime.length > 0) {
      const availabilityObj = availabilityDateTime.find((obj) => parseInt(obj.day_of_week) === dayOfWeek);
      if (availabilityObj) {
        return new Date(`1970-01-0${availabilityObj.day_of_week}T${availabilityObj[timeProperty as keyof AvailabilityDateTime]}`);
      }
    }
    return timeProperty === 'start_time' ? minTime : maxTime;
  };

  return (
    <DatePickerContainer>
    {timePicker ? (
      <DatePicker
        showTimeSelect
        dateFormat='d/M/yyyy h:mm a'
        selected={selectedDate || null}
        onChange={date => onDateChange(date)}
        popperPlacement="top-end"
        customInput={<CustomInputDate />}
        showYearDropdown={showYearDropdown}
        filterDate={isDateAvailable}
        filterTime={availabilityDateTime && filterTime}
        timeIntervals={15}
        timeCaption='Time'
        minTime={minTime}
        maxTime={maxTime}
      />
      ): (
        <DatePicker
        dateFormat='d/M/yyyy'
        selected={selectedDate || null}
        onChange={date => onDateChange(date)}
        customInput={<CustomInputDate />}
        showYearDropdown={showYearDropdown}
        filterDate={isDateAvailable}
      />
      )}

    </DatePickerContainer>
  )
}

export default DatePickerStyled
