import React, { useState } from 'react'
import {
  AvailabilityDateTime,
  FormsAPIProps,
  SiteSettingPlusProps,
  SiteSettingProps
} from '../api/types'

export interface SiteProps {
  id: number
  hostname: string
  port: number
  site_name: string
  root_page: string
  root_url: string
  is_default_site: boolean
  isDownloadable?: boolean
  availabiltyDateTime: Array<AvailabilityDateTime>
}

export const SiteContext = React.createContext<{
  siteInfor?: SiteProps
  otherSites?: Array<SiteProps>
  setSiteInfor?: Function
  setOtherSites?: Function
  siteSettings?: SiteSettingProps
  siteSettingPlus?: SiteSettingPlusProps
  registerYourInterestForm?: FormsAPIProps
  setRegisterYourInterestForm?: Function
  setFormBrochure?: (value: any) => void
  formBrochure?: any
}>({})

export const SiteContextProvider = (props: {
  Infor?: SiteProps
  OtherSites?: Array<SiteProps>
  siteSettings?: SiteSettingProps
  siteSettingPlus?: SiteSettingPlusProps
  children: any
}) => {
  const { children, Infor, OtherSites, siteSettings, siteSettingPlus } = props
  const [siteInfor, setSiteInfor] = useState<SiteProps | undefined>(Infor)
  const [otherSitesState, setotherSitesState] = useState<
    Array<SiteProps> | undefined
  >(OtherSites)
  const [registerYourInterestForm, setRegisterYourInterestForm] = useState<
    FormsAPIProps | undefined
  >()
  const [formBrochure, setFormBrochure] = useState<any>()

  return (
    <SiteContext.Provider
      value={{
        siteInfor: siteInfor,
        setSiteInfor: setSiteInfor,
        otherSites: otherSitesState,
        setOtherSites: setotherSitesState,
        siteSettings: siteSettings,
        siteSettingPlus: siteSettingPlus,
        registerYourInterestForm: registerYourInterestForm,
        setRegisterYourInterestForm: setRegisterYourInterestForm,
        formBrochure: formBrochure,
        setFormBrochure: setFormBrochure
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
