import { Component } from 'react'
import {
  LAYOUT_TEMPLATE,
  LocationCardProps,
  SelectOption
} from '../../services/api/types'
import { LocationCard } from './LocationCard'
import { GymLocationEmptyText, Wrapper } from './LocationCard.styled'
import { GYMS_FILTER, PAGE_TEMPLATE } from '../../services/api/constants'
import * as G from '../../styles/global.styles'
import dynamic from 'next/dynamic'
import { Col, Container, Row } from '../../styles/grid.styles'
import AlphabetList from './AlphabetList'

const SubHeader = dynamic(() => import('../header/SubHeader'), {
  ssr: false
})
const SubHeaderYouFitness = dynamic(
  () => import('../header/YouFitnessTemplate/SubHeader'),
  {
    ssr: false
  }
)

export interface GymLocationResultsProps {
  gyms: Array<LocationCardProps>
  title?: string
  onFilter?: Function
  loading?: boolean
  template?: LAYOUT_TEMPLATE
}

export class GymLocationResults extends Component<
  GymLocationResultsProps,
  any,
  any
> {
  constructor(props: GymLocationResultsProps) {
    super(props)
    this.state = { gyms: props.gyms, title: props.title, searchText: '' }
  }

  setGyms = (results: Array<LocationCardProps>) => {
    this.setState({ ...this.state, gyms: results })
  }

  setTitle = (title: string) => {
    this.setState({ ...this.state, title })
  }

  setSearchText = (searchText: string) => {
    this.setState({ ...this.state, searchText })
  }

  gymsFilter = (filter: SelectOption) => {
    this.props.onFilter && this.props.onFilter(filter)
  }

  render() {
    const { gyms, title, searchText } = this.state
    const { loading, template } = this.props

    const alphabet = ((gyms.length > 0 &&
      gyms.map((it: LocationCardProps) => it.title.charAt(0).toLowerCase())) ||
      []) as string[]

    return (
      <>
        <Wrapper template={template}>
          {template === PAGE_TEMPLATE.YOU_FITNESS ? (
            <SubHeaderYouFitness
              subHeaderText={title}
              showDropdown={false}
              showSeparator
              dropdownPlaceholder='Filter'
              onSortOptionChange={this.gymsFilter}
              dropdownOptions={GYMS_FILTER}
            />
          ) : (
            <SubHeader
              subHeaderText={title}
              showDropdown={false}
              showSeparator
              dropdownPlaceholder='Filter'
              onSortOptionChange={this.gymsFilter}
              dropdownOptions={GYMS_FILTER}
              template={template}
            />
          )}
          <div className='relative w-full'>
            <Container>
              {gyms && (
                <Row gutter={5}>
                  {gyms?.map((gym: LocationCardProps, idx: number) => (
                    <Col md={4} key={idx} style={{ marginBottom: 10 }}>
                      <LocationCard
                        template={template}
                        {...gym}
                        cardId={
                          alphabet.indexOf(
                            gym.title.charAt(0).toLowerCase()
                          ) === idx
                            ? gym.title.charAt(0).toLowerCase()
                            : ''
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}
              {gyms &&
                gyms.length > 0 &&
                template === PAGE_TEMPLATE.YOU_FITNESS && (
                  <AlphabetList data={[...new Set(alphabet)]} />
                )}
              {(!gyms || (gyms && gyms.length === 0)) && (
                <GymLocationEmptyText>
                  We couldn’t find a match for "{searchText}". Please try
                  another search!
                </GymLocationEmptyText>
              )}
            </Container>
            {loading ? (
              <G.SpinnerOverlayWrap>
                <G.SpinnerWrap>
                  <G.Spinner />
                </G.SpinnerWrap>
              </G.SpinnerOverlayWrap>
            ) : null}
          </div>
        </Wrapper>
      </>
    )
  }
}

export default GymLocationResults
