import styled from 'styled-components'
import tw from 'twin.macro'

export const Button = styled.button`
  // base
  ${tw`px-4 py-4 lg:py-2 transition-colors duration-300 uppercase italic`}
  
  // primary
  ${props =>
    (!!!props.color || props.color == 'orange') &&
    tw`bg-orange hover:bg-orange__dark text-white`}
  
  // secondary
  ${props =>
    props.color == 'blue' && tw`bg-blue hover:bg-blue__dark text-white`}
  
  // white
  ${props =>
    (props.color == 'white' || props.color == 'tertiary') &&
    tw`bg-white hover:bg-grey__light text-blue`}
  
  // grey
  ${props =>
    props.color == 'grey' &&
    tw`bg-grey__light hover:bg-grey text-blue hover:text-white`}

  // blue-deep
  ${props =>
    props.color == 'blue-deep' && tw`bg-blue__deep hover:bg-blue__dark2 text-white`}
`

export const ButtonLink = styled.a<{
  color?:
    | 'orange'
    | 'blue'
    | 'white'
    | 'tertiary'
    | 'primary'
    | 'secondary'
    | 'grey'
    | 'blue-deep'
    | 'secondary-alt'
    | string
}>`
  // base
  ${tw`px-4 py-4 lg:py-2 transition-colors duration-300 uppercase cursor-pointer inline-block`}
  ${({ color }) => {
    switch (color) {
      case 'blue':
      case 'secondary':
        return tw`bg-blue hover:bg-blue__dark text-white`
      case 'orange':
      case 'primary':
        return tw`bg-orange hover:bg-orange__dark text-white`
      case 'white':
      case 'tertiary':
        return tw`bg-white hover:bg-grey__light text-blue`
      case 'grey':
        return tw`bg-grey hover:bg-grey__dark text-white`
      case 'blue-deep':
        return tw`bg-blue__deep hover:bg-blue__dark2 text-white`
      case 'secondary-alt':
        return tw`bg-blue__deep hover:bg-blue__dark2 text-white ml-1`
      default:
        return tw`bg-orange hover:bg-orange__dark text-white`
    }
  }}
`

export default Button
